<template>
  <moe-page title="APP广告">
    <moe-inquire @search="appAdvertSearch">
      <el-form-item label="广告编号">
        <el-input :value="appAdvertParams.id" placeholder="输入广告编号" @input="(value)=> appAdvertParams.id = $moe_formatter.formatterId(value)" clearable />
      </el-form-item>
      <el-form-item label="跳转类型">
        <moe-select v-model="appAdvertParams.form" type="appAdvertForm" placeholder="选择跳转类型" />
      </el-form-item>
      <el-form-item label="状态">
        <moe-select v-model="appAdvertParams.showStatus" type="mallAdvertStatus" placeholder="选择状态" />
      </el-form-item>
      <el-form-item label="广告位置">
        <moe-select v-model="appAdvertParams.type" type="appAdvertType" placeholder="选择广告位置" />
      </el-form-item>
      <el-form-item label="媒体类型">
        <moe-select v-model="appAdvertParams.urlType" type="appAdvertUrlType" placeholder="选择媒体类型" />
      </el-form-item>
    </moe-inquire>

    <moe-table ref="appAdvertTable" url="/AppAdvert/page" :params="appAdvertParams" :number-show="false">
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/appAdvert/add')">APP广告添加</el-button>
      </template>

      <el-table-column prop="id" label="广告编号" />
      <el-table-column prop="appAdvertType" label="广告位置" width="150">
        <template slot-scope="{ row }">
          {{ $moe_format.getAppAdvertType(row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="urlType" label="媒体类型">
        <template slot-scope="{ row }">
          {{ row.urlType === '1' ? '图片' : '视频' }}
        </template>
      </el-table-column>
      <el-table-column prop="coverUrl" label="媒体" width="180">
        <template slot-scope="{ row }">
          <moe-image v-if="row.urlType === '1'" :src="row.coverUrl" width="100px" height="100px" />
          <video v-else-if="row.urlType === '2'" controls width="100px" height="100px" :src="row.coverUrl"></video>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" />
      <el-table-column label="创建时间" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="展示时间" min-width="310">
        <template slot-scope="{ row }">
          <div v-if="row.startTime">
            {{ `${$moe_time.getTimeStamp(row.startTime)} 至 ${$moe_time.getTimeStamp(row.endTime)}` }}
          </div>
          <div v-else>长期</div>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="{ row }">
          <div :class="$moe_format.getMallContentStatusColor(row.showStatus)">
            {{ $moe_format.getMallContentStatus(row.showStatus) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark" :show-overflow-tooltip="true" />

      <el-table-column label="操作" width="400" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="success" size="mini" icon="el-icon-view" @click="$moe_coordinator.navigateTo(`/appAdvert/detail`, { id: row.id })">详情</el-button>
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/appAdvert/add`, { id: row.id })">编辑</el-button>
          <el-button v-if="['RUNNING'].includes(row.showStatus)" type="warning" size="mini" icon="el-icon-video-pause" @click="handleShelf(row, 2, '暂停')">暂停</el-button>
          <el-button v-if="['STOP'].includes(row.showStatus)" type="info" size="mini" icon="el-icon-video-play" @click="handleShelf(row, 1, '启用')">启用</el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row.id)">删除</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'AppAdvert',
  data() {
    let appAdvertParams = {
      id: '',
      pageNum: 1,   //当前页
      pageSize: 10, //每页条数
      form: '',     //跳转类型 1：外部 2：内部
      showStatus: '',   //1:上架 2：下架
      type: '',     //位置：1.app首页轮播图 2.app打开加载广告
      urlType: '',  //内容类型：1.图片 2.视频
    }
    return {
      appAdvertParams,
    }
  },
  methods: {
    /** 搜索 */
    appAdvertSearch(isSearch) {
      if (!isSearch) {
        this.appAdvertParams = {
          id: '',
          pageNum: 1,
          pageSize: 10,
          form: '',
          showStatus: '',
          type: '',
          urlType: '',
        }
      }

      this.$refs['appAdvertTable'].searchData();
    },
    /** 状态修改 */
    handleShelf({ id }, status, text) {
      this.$moe_layer.confirm(`您确定要“${text}”当前选项吗？`, () => {
        this.$moe_api.ADVERT_API.appAdvertShelf({ id, status }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('操作成功');
            this.$refs['appAdvertTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
    /** 删除 */
    handleDelete(id) {
      this.$moe_layer.confirm(`您确定要删除当前选项吗？`, () => {
        this.$moe_api.ADVERT_API.appAdvertDel({ id }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('删除成功');
            this.$refs['appAdvertTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
  }
}
</script>